import React, { useEffect, useRef } from "react";
import { ChangeEvent, useState } from "react";
import DataTable from "react-data-table-component";
import { useTable } from "react-table";
import DataTableExtensions from "react-data-table-component-extensions";
import { makeStyles } from "@material-ui/core/styles";
import Book from "@material-ui/icons/Book";
import Menu from "@material-ui/icons/Menu";
import Select from "react-select";
import Header from "../../Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// import Menu from "@material-ui/icons/Bar";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Add from "@material-ui/icons/Book";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import styles1 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import ReactTable from "components/ReactTable/ReactTable";
import { BarLoader, BeatLoader, DotLoader } from "react-spinners";
import {
  BackupRounded,
  CheckCircle,
  CheckCircleOutline,
  Folder,
  Delete,
  RadioButtonChecked,
  AddCircle,
} from "@material-ui/icons";
import {
  CheckCircleOutlineTwoTone,
  DocumentScannerOutlined,
  DocumentScannerSharp,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
const useStyles = makeStyles(styles);
const useStyles1 = makeStyles(styles1);
const currentUser = JSON.parse(localStorage.getItem("user"));

//
import { FileUploadOutlined, AutorenewOutlined } from "@mui/icons-material";
import { maxWidth } from "@mui/system";
// import cover from "../../assets/img/";

// import cover from "assets/img/covers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
export default function Mapspage() {
  const [booklist, setbooklist] = useState([]);
  const [wordsToBeDeleted, setWordsToBeDeleted] = useState([]);
  const [matchWords, setMatchWords] = useState({});
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState("");
  const [booktoedit, setBookToEdit] = useState("");
  const [bookTitle, setBookTitle] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [matchOption, setMatchOption] = useState("");
  const [matchToWord, setMatchToWord] = useState("");
  const [allUploadedBooksArray, setAllUploadedBooksArray] = useState([]);
  const [
    allCompeletedScanBooksArray,
    setAllCompeletedScanBooksArray,
  ] = useState([]);
  const [
    allInProgressScanBooksArray,
    setAllInProgressScanBooksArray,
  ] = useState([]);
  const [allFailedScanBooksArray, setAllFailedScanBooksArray] = useState([]);
  const [bookauthor, setBookAuthor] = useState("");
  const [newbookauthor, setNewBookAuthor] = useState("");
  const [bookNumber, setBookNumber] = useState("");
  const [bookLink, setBookLink] = useState("");
  const [summaryData, setSummaryData] = useState({});
  const [totalSummaryData, setTotalSummaryData] = useState({});
  const [uniqueSummaryData, setUniqueSummaryData] = useState({});
  const [previousbookauthor, setPreviousBookAuthor] = useState("");
  const [cover, setCover] = useState("");
  const [previousbookNumber, setPreviousBookNumber] = useState("");
  const [previouspriority, setPreviousPriority] = useState("");
  const [newbookNumber, setNewBookNumber] = useState("");
  const [newpriority, setNewPriority] = useState("");
  const [tableHeading, setTableHeading] = useState("Words ");
  const [tableView, setTableView] = useState("");
  const [chapterDetail, setChapterDetail] = useState({});
  const [isVocabularyView, setIsVocabularyView] = useState(true);
  const [chapterView, setChapterView] = useState([]);
  const [bookPriority, setBookPriority] = useState("");
  const [startPage, setStartPage] = useState("");
  const [endPage, setEndPage] = useState("");
  const [bookToDisplay, setBookToDisplay] = useState("");
  const [wordListCount, setWordListCount] = useState("");
  const [editorMode, setEditorMode] = useState(false);
  const [wordcountarr, setWordCountArr] = useState([]);
  const [percentageRootWords, setPercentageRootWords] = useState("");
  const [modal, setModal] = React.useState(false);
  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadStudyList, setLoadStudyList] = useState(false);
  const [openModalMatch, setOpenModalMatch] = React.useState(false);
  const [openModalStudyList, setOpenModalStudyList] = React.useState(false);
  const [studyList, setStudyList] = React.useState([]);
  const [inputValues, setInputValues] = useState([]);
  const [loadChapterView, setLoadChapterView] = useState(false);
  const [highlightedText, setHighlightedText] = useState("");
  const [showAddChapterBtn, setShowAddChapterBtn] = useState(false);
  const [openChapterModal, setOpenChapterModal] = useState(false);
  const [removeChapter, setRemoveChapter] = useState(false);
  const [newChapter, setNewChapter] = useState("");
  const [oldChapter, setOldChapter] = useState("");
  const [isInEditChapterMode, setIsInEditChapterMode] = useState("");
  const [newContent, setNewContent] = useState("");
  // Search content
  const [initialParagraph, setInitialParagraph] = useState("");
  const [paragraph, setParagraph] = useState(initialParagraph);
  const [searchTerm, setSearchTerm] = useState("");
  const [highlighted, setHighlighted] = useState(false);
  const inputRef = useRef(null);
  //End
  const classes = useStyles();
  const classes1 = useStyles1();
  const data = React.useMemo(
    () => [
      {
        col1: "number",
        col2: " ",
      },
      {
        col1: "% of total",
        col2: "",
      },
      {
        col1: "% root words",
        col2: "",
      },
      {
        col1: "% non-root words",
        col2: "",
      },
    ],
    []
  );
  const columns = React.useMemo(
    () => [
      {
        Header: " ",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "All Words",
        accessor: "col2",
      },
      {
        Header: "	1-4 letters",
        accessor: "col4",
      },
      {
        Header: "	5-7 letters",
        accessor: "col5",
      },
      {
        Header: "8-10 letters",
        accessor: "col6",
      },
      {
        Header: ">10 letters",
        accessor: "col7",
      },
    ],
    []
  );
  let deletedWordsArr = [];
  let selectedWordsArr = [];
  const fileInputRef = useRef(null);

  const columns1 = React.useMemo(
    () => [
      {
        Header: "Word",
        accessor: "word",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.word}

              {row.row.original.match ? (
                <div>
                  {/* {row.row.original.match.push('jane')} */}
                  <Select
                    // value={matchOption}
                    onChange={(e) => {
                      // saveMatchWordOption(e.value, row.row.original.word);
                      saveSelection(e.value, row.row.original.word, 1);
                    }}
                    options={row.row.original.match}
                  />
                </div>
              ) : (
                <span></span>
              )}
            </div>
          );
        },
      },
      {
        Header: "Add Match",
        accessor: "addmatch",
      },
      {
        Header: "High_Value",
        accessor: "select",
        //   Cell: (row) => {
        //     return  <input type="checkbox"/>

        // },
      },
      {
        Header: "Delete",
        accessor: "delete",
        //   Cell: (row) => {
        //     return  editorMode ? <input type="checkbox"/>
        //     : <input type="checkbox" disabled />
        // },
      },
      {
        Header: "Word_Count",
        accessor: "wordcount",
      },
      {
        Header: "Word_Length",
        accessor: "wordlength",
      },
      {
        Header: "Freq",
        accessor: "frequency",
      },
      {
        Header: "Wordlist",
        accessor: "wordlist",
      },
      {
        Header: "Incidence",
        accessor: "incidence",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  const wordColumns = [
    {
      name: "Word",
      selector: (row) => row.word,
      sortable: true,
    },
    {
      name: "Word Count",
      selector: (row) => row.wordcount,
      sortable: true,
    },
    {
      name: "Frequency",
      selector: (row) => row.frequency,
      sortable: true,
    },
    {
      name: "Root Meaning",
      selector: (row) => row.wordlist,
      sortable: true,
      cell: (d) => (
        <div>
          <p>{d.wordlist[0]}</p>
          <br />
          <p>{d.wordlist[1]}</p>
          <p>{d.wordlist[2]}</p>
        </div>
      ),
    },
    {
      name: "Root Incidence",
      selector: (row) => row.incidence,
      sortable: true,
      cell: (d) => (
        <div>
          <div>{d.incidence[0] || ""}</div>
          <br />
          <div>{d.incidence[1] || ""}</div>
          <br />
          <div>{d.incidence[2] || ""}</div>
        </div>
      ),
    },
  ];

  const cellStyle = {
    textAlign: "center",
    border: "solid 1px gray",
    background: "rgba(255, 205, 0, 0.25)",
  };

  const cellStyle1 = {
    textAlign: "center",
    fontWeight: "bold",
    border: "solid 1px gray",
    background: "rgba(52, 86, 106, 0.25)",
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = "/covers/white.png";
  };

  let options = [];

  let wordData = [];

  let tableCount = 1;
  //   Display words in ascending order of frequency starting with 0
  wordcountarr.sort((a, b) => {
    if (
      parseInt(a.frequency) !== undefined &&
      parseInt(b.frequency) !== undefined
    ) {
      return parseInt(a.frequency) > parseInt(b.frequency) ? 1 : -1;
    } else {
      return parseInt(a.frequency) !== undefined ? 1 : -1;
    }
  });
  wordcountarr.map((element, index) => {
    const inputId = `input-${index}`;
    // subhash['rootword'] = element['rootword']
    if (element["wordlist"].length) {
      for (let i = 0; i < element["wordlist"].length; i++) {
        let subhash = {};
        subhash["count"] = tableCount;
        subhash["word"] = element["word"];
        subhash["wordcount"] = element["wordcount"];
        subhash["wordlength"] = element["word"].length;
        subhash["frequency"] = parseInt(element["frequency"]);
        subhash["wordlist"] = element["wordlist"][i];
        subhash["incidence"] = element["incidence"][i];
        subhash["addmatch"] =
          parseInt(element["frequency"]) == 0 ? (
            <div>
              <input
                type="text"
                style={{
                  width: "100px",
                  border: "1px solid #999",
                  borderRadius: "5px",
                  padding: "5px",
                }}
                id={index}
              />
              <Tooltip title="Save" placement="right">
                <CheckCircleOutlineTwoTone
                  style={{
                    color: "#ff9100",
                    marginLeft: "40px",
                  }}
                  type="button"
                  onClick={() => {
                    // saveMatchWord(element["word"], index);
                    document.getElementById(index).style.color = "#ff9100";
                    saveSelection(
                      document.getElementById(index).value,
                      element["word"],
                      1
                    );
                  }}
                />
              </Tooltip>
            </div>
          ) : (
            ""
          );

        subhash["select"] = (
          <input
            type="checkbox"
            className="selectedCheckbox"
            value={element["word"]}
          />
        );

        subhash["delete"] = (
          <input
            type="checkbox"
            className="deleteCheckbox"
            value={element["word"]}
            onClick={(e) => {
              deletedWordsArr.push(e.target.value);
            }}
          />
        );

        wordData.push(subhash);
        tableCount = tableCount + 1;
      }
    } else {
      let subhash = {};
      subhash["count"] = tableCount;
      subhash["word"] = element["word"];
      subhash["wordcount"] = element["wordcount"];
      subhash["wordlength"] = element["word"].length;
      subhash["frequency"] = parseInt(element["frequency"]) == 0 ? (
        <div>

          <input
            type="text"
            style={{
              width: "100px",
              border: "1px solid #999",
              borderRadius: "5px",
              padding: "5px",
            }}
            defaultValue={0}
            id={inputId}
          />
          <Tooltip title="Save" placement="right">
            <CheckCircleOutlineTwoTone
              style={{
                color: "#ff9100",
                marginLeft: "40px",
              }}
              type="button"
              onClick={() => {
                // saveMatchWord(element["word"], index);
                // document.getElementById(index).style.color = "#ff9100";
                addFrequencyToWord(
                  document.getElementById(inputId).value,
                  element["word"]);
              }}
            />
          </Tooltip>
        </div>
      ) : (
        parseInt(element["frequency"])
      );

      subhash["wordlist"] = "";
      subhash["addmatch"] =
        parseInt(element["frequency"]) == 0 ? (
          <div>
            <input
              type="text"
              style={{
                width: "100px",
                border: "1px solid #999",
                borderRadius: "5px",
                padding: "5px",
              }}
              id={index}
            />
            <Tooltip title="Save" placement="right">
              <CheckCircleOutlineTwoTone
                style={{
                  color: "#ff9100",
                  marginLeft: "40px",
                }}
                type="button"
                onClick={() => {
                  // saveMatchWord(element["word"], index);
                  document.getElementById(index).style.color = "#ff9100";
                  saveSelection(
                    document.getElementById(index).value,
                    element["word"],
                    1
                  );
                }}
              />
            </Tooltip>
          </div>
        ) : (
          ""
        );

      subhash["select"] = (
        <input
          type="checkbox"
          className="selectedCheckbox"
          value={element["word"]}
        />
      );

      subhash["delete"] = (
        <input
          type="checkbox"
          className="deleteCheckbox"
          value={element["word"]}
          onClick={(e) => {
            deletedWordsArr.push(e.target.value);
          }}
        />
      );

      subhash["match"] = parseInt(element["frequency"]) == 0 && matchWords[element["word"]];

      subhash["incidence"] = "";

      wordData.push(subhash);
      tableCount = tableCount + 1;
    }
  });
  // wordcountarr.forEach(element => {
  //   let subhash = {}
  //   subhash['word'] = element['word']
  //   subhash['wordcount'] = element['wordcount']
  //   subhash['frequency'] = parseInt(element['frequency'])
  //   // subhash['rootword'] = element['rootword']
  //   subhash['wordlist'] = element['wordlist']
  //   let num = element['incidence']
  //   // num.map((i) => parseInt(i))
  //   // let arr =[]
  //   num.forEach(elemen => {
  //     arr.push(parseInt(elemen))
  //   });
  //   const arrOfNum = num.map(str => {
  //     return Number(str);
  //   });
  //   subhash['incidence'] = num
  //   wordData.push(subhash)
  // });

  const masterStudyList = () => {
    setLoadStudyList(true);
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      const bookName = bookToDisplay;
      if (bookName == "") {
        setLoadStudyList(false);
        return toast.error("Please select the name of a book ", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        fetch(process.env.REACT_APP_API_URL + `masterStudyList/${bookName}`, {
          headers,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.code === 200) {
              setStudyList(data.data);
              setOpenModalStudyList(true);
              setLoadStudyList(false);
            } else {
              setLoadStudyList(false);
              toast.error(data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => {
            setLoadStudyList(false);
            toast.error("Something went wrong. Please try again", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  };

  const saveMatchWordOption = (matchWord, word) => {
    setMatchOption(matchWord);
    setMatchToWord(word);
    setOpenModalMatch(true);
  };

  const saveMatchWord = (word, index) => {
    setLoadStudyList(true);
    const inputMatch = document.getElementById(index);

    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      const bookName = bookToDisplay.slice(0, -4);
      let myWord = "";
      let match = "";

      if (matchToWord !== "" && matchOption !== "") {
        myWord = matchToWord;
        match = matchOption;
      } else {
        myWord = word;
        match = inputMatch.value;
      }
      if (myWord === "" || match === "") {
        return toast.error("Please select a match", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.info("Processing...", {
          position: toast.POSITION.TOP_CENTER,
        });
        axios
          .patch(
            process.env.REACT_APP_API_URL +
            `save-match-word/${bookName}/${myWord}/${match}`,
            { headers }
          )
          .then((res) => {
            if (res.data.code === 200) {
              toast.success("saved Successfully", {
                position: toast.POSITION.TOP_CENTER,
              });
              setLoadStudyList(false);
              setMatchOption("");
              setMatchToWord("");
              // setWordCountArr([]);
              let response = res.data.data.wordsToCount;
              if (res.data.data.csvData) {
                let hash = {};
                for (const key in res.data.data.csvData) {
                  hash[key] = [];
                  res.data.data.csvData[key].forEach((element) => {
                    hash[key].push({ value: element, label: element });
                  });
                }
                setMatchWords(hash);
              }
              if (res.data.data.summaryData) {
                setSummaryData(res.data.data.summaryData["total"]);
                setTotalSummaryData(res.data.data.summaryData["total"]);
                setUniqueSummaryData(res.data.data.summaryData["unique"]);
              }
              if (res.data.data.rootwordpercentage !== "") {
                setPercentageRootWords(res.data.data.rootwordpercentage);
              }
              let arrRoot = [];
              let arrNonRoot = [];
              for (const key in response) {
                let incidenceArr = [];
                response[key].wordlist.forEach((wordlistcount) => {
                  incidenceArr.push(response[key][wordlistcount]);
                });

                response[key].incidence = incidenceArr;
                let subhash = {};
                subhash["word"] = response[key].originalword;
                subhash["wordcount"] = response[key].wordcount;
                subhash["frequency"] = response[key].frequency;
                subhash["rootword"] = response[key].rootword;
                subhash["wordlist"] = response[key].wordlist;
                subhash["incidence"] = response[key].incidence || [];
                if (response[key].rootword == "true") {
                  arrRoot.push(subhash);
                } else if (response[key].rootword == "false") {
                  arrNonRoot.push(subhash);
                }
                if (res.data.data.wordlistCount) {
                  setWordListCount(
                    Object.keys(res.data.data.wordlistCount).length
                  );
                }
              }
              setWordCountArr(arrNonRoot);
              setBookAuthor("");
              setBookNumber("");
              setBookPriority("");
              setFile("");
            } else {
              setLoadStudyList(false);
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => {
            // console.log(error);
            setLoadStudyList(false);
          });
        setOpenModalMatch(false);
      }
      setMatchOption("");
      setMatchToWord("");
    }
  };
  const removeMatch = (word) => {
    let sortedValue = inputValues.filter((item) => item.word != word);
    setInputValues(sortedValue);
    localStorage.setItem("inputValue", JSON.stringify(sortedValue));
  };
  const addFrequencyToWord = (frequency, word) => {
    const bookName = bookToDisplay.endsWith('.pdf')
      ? bookToDisplay.slice(0, -4)
      : bookToDisplay;
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "bookscan/add-frequency",
          { bookName,frequency, word },
          { headers }
        )
        .then((response) => {
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            setSelectedOptionFunc(bookToDisplay);
          }else{
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((error) => { });

    }


  };
  const saveSelection = (match, original_word, step) => {
    const storedValue = JSON.parse(localStorage.getItem("inputValue"));
    if (step == 1) {
      let value = [];
      const data = {
        word: original_word,
        match,
      };
      if (storedValue == null) {
        value.push(data);
        localStorage.setItem("inputValue", JSON.stringify(value));
      } else {
        let sortedValue = storedValue.filter(
          (item) => item.word != original_word
        );
        const updatedItems = [...sortedValue, data];
        localStorage.setItem("inputValue", JSON.stringify(updatedItems));
      }
    } else if (step == 2) {
      setInputValues([...storedValue]);
      setOpenModalMatch(true);
    } else if (step == 3) {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        const bookName = bookToDisplay.endsWith('.pdf')
          ? bookToDisplay.slice(0, -4) // Remove the last 4 characters
          : bookToDisplay;
        if (inputValues.length == 0) {
          return toast.error("Please select a match", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (bookName == "") {
          return toast.error("Please select a book", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.info("Processing...", {
            position: toast.POSITION.TOP_CENTER,
          });
          const all_matches = inputValues;
          axios
            .patch(
              process.env.REACT_APP_API_URL + `save-match-word/${bookName}`,
              { all_matches },
              { headers }
            )
            .then((res) => {
              if (res.data.code === 200) {
                toast.success("saved Successfully", {
                  position: toast.POSITION.TOP_CENTER,
                });
                localStorage.removeItem("inputValue");
                setSelectedOptionFunc(bookToDisplay)
                setLoadStudyList(false);
                setMatchOption("");
                setMatchToWord("");
                // let response = res.data.data.wordsToCount;
                // if (res.data.data.csvData) {
                //   let hash = {};
                //   for (const key in res.data.data.csvData) {
                //     hash[key] = [];
                //     res.data.data.csvData[key].forEach((element) => {
                //       hash[key].push({ value: element, label: element });
                //     });
                //   }
                //   setMatchWords(hash);
                // }
                // if (res.data.data.summaryData) {
                //   setSummaryData(res.data.data.summaryData["total"]);
                //   setTotalSummaryData(res.data.data.summaryData["total"]);
                //   setUniqueSummaryData(res.data.data.summaryData["unique"]);
                // }
                // if (res.data.data.rootwordpercentage !== "") {
                //   setPercentageRootWords(res.data.data.rootwordpercentage);
                // }
                // let arrRoot = [];
                // let arrNonRoot = [];
                // for (const key in response) {
                //   let incidenceArr = [];
                //   response[key].wordlist.forEach((wordlistcount) => {
                //     incidenceArr.push(response[key][wordlistcount]);
                //   });

                //   response[key].incidence = incidenceArr;
                //   let subhash = {};
                //   subhash["word"] = response[key].originalword;
                //   subhash["wordcount"] = response[key].wordcount;
                //   subhash["frequency"] = response[key].frequency;
                //   subhash["rootword"] = response[key].rootword;
                //   subhash["wordlist"] = response[key].wordlist;
                //   subhash["incidence"] = response[key].incidence || [];
                //   if (response[key].rootword == "true") {
                //     arrRoot.push(subhash);
                //   } else if (response[key].rootword == "false") {
                //     arrNonRoot.push(subhash);
                //   }
                //   if (res.data.data.wordlistCount) {
                //     setWordListCount(
                //       Object.keys(res.data.data.wordlistCount).length
                //     );
                //   }
                // }
                // setWordCountArr(arrNonRoot);
                // setBookAuthor("");
                // setBookNumber("");
                // setBookPriority("");
                // setFile("");
              } else {
                setLoadStudyList(false);
                toast.error(res.data.message, {
                  position: toast.POSITION.TOP_CENTER,
                });
              }
            })
            .catch((error) => {
              // console.log(error);
              setLoadStudyList(false);
            });
          setOpenModalMatch(false);
        }
        setMatchOption("");
        setMatchToWord("");
      }
    }
  };

  const deletedWords = (e) => {
    var checkedValue = [];
    var inputElements = document.getElementsByClassName("deleteCheckbox");
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        checkedValue.push(inputElements[i].value);
      }
    }
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      if (checkedValue.length > 0) {
        axios
          .post(
            process.env.REACT_APP_API_URL +
            "books/" +
            bookToDisplay.replace(".pdf", "") +
            "/edit",
            { checkedValue },
            { headers }
          )
          .then((response) => {
            if (response.data.code === 200) {
              toast.success("Deleted Successfully", {
                position: toast.POSITION.TOP_CENTER,
              });
              window.location.reload(false);
            }
          })
          .catch((error) => { });
      }
    }
  };

  const getAllISBN = (e) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + currentUser.accessToken,
    };
    axios
      .get(process.env.REACT_APP_API_URL + "isbns/books", { headers })
      .then((response) => {
        toast.success(
          "Updating all books ISBN, Author Name and Book Covers. Please check in a few minutes"
        );
      })
      .catch((error) => {
        console.log(error);

        toast.error("Failed to Update all books ISBN.");
      });
  };

  const getBookToEdit = (e) => {
    setBookTitle(e.currentTarget.dataset.id)
    setBookToEdit(e.currentTarget.dataset.id);
    setStartPage("");
    setEndPage("");
    if (e.currentTarget.dataset.id === "") {
      setModal(false);
    } else {
      let book = e.currentTarget.dataset.id
        .replace(".pdf", "")
        .replace("%20", " ");
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        fetch(process.env.REACT_APP_API_URL + "books/" + book, { headers })
          .then((res) => res.json())
          .then((data) => {
            setPreviousBookAuthor(data.book_author);
            setPreviousBookNumber(data.book_isbn);
            setPreviousPriority(data.book_priority);
            setStartPage(data.startPage);
            setEndPage(data.endPage);
            setModal(true);
          });
      }
    }
  };

  const getBookToScan = (e) => {
    let dataset = e.currentTarget.dataset.id;
    setBookToEdit(e.currentTarget.dataset.id);
    if (dataset === "") {
      setModal1(false);
    } else {
      setBookToEdit(dataset);
      setModal1(true);
    }
  };
  const scanBook = (e) => {
    if (booktoedit === "") {
      toast.error("please choose book to scan");
    } else {
      setModal1(false);
      toast.success("Scan in progress");
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        axios
          .post(
            process.env.REACT_APP_API_URL + "admin/bookscan/scanbook",
            {
              booktoedit,
            },
            { headers }
          )
          .then((response) => {
            setBookAuthor("");
            setBookNumber("");
            setBookPriority("");
            setFile("");
            let book = booktoedit.replace(".pdf", "")
            for (let i = 0; i < allUploadedBooksArray.length; i++) {
              if (allUploadedBooksArray[i][1] == book) {
                allUploadedBooksArray[i][3] = "in progress";
              }
            }
            setAllUploadedBooksArray(allUploadedBooksArray);
            let filter1 = allCompeletedScanBooksArray.filter(arr => arr !== booktoedit);
            let filter2 = allInProgressScanBooksArray.filter(arr => arr !== booktoedit).concat(booktoedit);
            setAllCompeletedScanBooksArray(filter1);
            setAllInProgressScanBooksArray(filter2);
          })
          .catch((error) => {
            if (booktoedit === "") {
              toast.error("please choose book to scan");
            } else {
              toast.error("scan fail");
            }
          });
      }
    }
  };

  const editBookInfo = (e) => {
    e.preventDefault();
    let bookinfo = {
      booktoedit,
      newbookauthor,
      newbookNumber,
      newpriority,
      newBookName: bookTitle,
      startPage,
      endPage
    };
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      if (bookTitle == "") {
        return toast.error("Book title can't be empty.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        axios
          .post(
            process.env.REACT_APP_API_URL + "admin/bookscan/editbook/" + bookinfo.booktoedit,
            {
              bookinfo,
            },
            { headers }
          )
          .then((response) => {
            if (response.data.code == 200) {
              toast.success("Successfully updated book", {
                position: toast.POSITION.TOP_CENTER,
              });
              setBookAuthor("");
              setBookNumber("");
              setBookPriority("");
              setFile("");
              window.location.reload(false);
            } else {
              return toast.error(response.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }

          })
          .catch((error) => {
            if (booktoedit === "") {
              toast.error("Please choose book to edit", {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              toast.error("update fail", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          });
      }
    }
  };
  const getRootWords = (e) => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      setWordCountArr([]);
      axios
        .get(
          process.env.REACT_APP_API_URL +
          "admin/bookscan/" +
          bookToDisplay.replace(".pdf", ""),
          { headers }
        )
        .then((res) => {
          setTableHeading("Root Words ");
          let response = res.data.wordsToCount;
          if (res.data.csvData) {
            let hash = {};
            for (const key in res.data.csvData) {
              hash[key] = [];
              res.data.csvData[key].forEach((element) => {
                hash[key].push({ value: element, label: element });
              });
            }
            setMatchWords(hash);
          }
          if (res.data.summaryData) {
            setSummaryData(res.data.summaryData["total"]);
            setTotalSummaryData(res.data.summaryData["total"]);
            setUniqueSummaryData(res.data.summaryData["unique"]);
          }
          if (res.data.rootwordpercentage !== "") {
            setPercentageRootWords(res.data.rootwordpercentage);
          }
          let arrRoot = [];
          let arrNonRoot = [];
          for (const key in response) {
            let incidenceArr = [];
            response[key].wordlist.forEach((wordlistcount) => {
              incidenceArr.push(response[key][wordlistcount]);
            });

            response[key].incidence = incidenceArr;
            let subhash = {};
            subhash["word"] = response[key].originalword;
            subhash["wordcount"] = response[key].wordcount;
            subhash["frequency"] = response[key].frequency;
            subhash["rootword"] = response[key].rootword;
            subhash["wordlist"] = response[key].wordlist;
            subhash["incidence"] = response[key].incidence || [];
            if (response[key].rootword == "true") {
              arrRoot.push(subhash);
            } else {
              arrNonRoot.push(subhash);
            }
            if (res.data.wordlistCount) {
              setWordListCount(Object.keys(res.data.wordlistCount).length);
            }
          }
          setWordCountArr(arrRoot);
          setBookAuthor("");
          setBookNumber("");
          setBookPriority("");
          setFile("");
        })

        .catch((error) => { });
    }
  };
  const getHeadWords = (e) => {
    e.preventDefault();
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      setWordCountArr([]);
      axios
        .get(
          process.env.REACT_APP_API_URL +
          "admin/bookscan/" +
          bookToDisplay.replace(".pdf", ""),
          { headers }
        )
        .then((res) => {
          setTableHeading("View as Head Words ");
          let response = res.data.wordsToCount;
          if (res.data.csvData) {
            let hash = {};
            for (const key in res.data.csvData) {
              hash[key] = [];
              res.data.csvData[key].forEach((element) => {
                hash[key].push({ value: element, label: element });
              });
            }
            setMatchWords(hash);
          }
          if (res.data.summaryData) {
            setSummaryData(res.data.summaryData["total"]);
            setTotalSummaryData(res.data.summaryData["total"]);
            setUniqueSummaryData(res.data.summaryData["unique"]);
          }
          if (res.data.rootwordpercentage !== "") {
            setPercentageRootWords(res.data.rootwordpercentage);
          }
          let arrHead = [];

          for (const key in response) {
            let incidenceArr = [];
            response[key].wordlist.forEach((wordlistcount) => {
              incidenceArr.push(response[key][wordlistcount]);
            });

            response[key].incidence = incidenceArr;
            let subhash = {};
            subhash["wordcount"] =
              response[key].wordfamilycount || response[key].wordcount;
            subhash["frequency"] = response[key].frequency;
            subhash["rootword"] = response[key].rootword;
            subhash["wordlist"] = response[key].wordlist;
            subhash["incidence"] = response[key].incidence || [];
            subhash["word"] =
              response[key].headword !== undefined &&
                response[key].frequency != "0"
                ? response[key].headword
                : response[key].originalword;
            arrHead.push(subhash);

            if (res.data.wordlistCount) {
              setWordListCount(Object.keys(res.data.wordlistCount).length);
            }
          }
          const uniqueIds = [];

          const unique = arrHead.filter((element) => {
            const isDuplicate = uniqueIds.includes(element.word);

            if (!isDuplicate) {
              uniqueIds.push(element.word);

              return true;
            }

            return false;
          });
          setWordCountArr(unique);
          setBookAuthor("");
          setBookNumber("");
          setBookPriority("");
          setFile("");
        })
        .catch((error) => { });
    }
  };

  const getNonRootWords = (e) => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      setWordCountArr([]);
      axios
        .get(
          process.env.REACT_APP_API_URL +
          "admin/bookscan/" +
          bookToDisplay.replace(".pdf", ""),
          { headers }
        )
        .then((res) => {
          setTableHeading("Non Root Words ");
          let response = res.data.wordsToCount;
          // console.log(res.data.csvData)
          if (res.data.csvData) {
            let hash = {};
            for (const key in res.data.csvData) {
              hash[key] = [];
              res.data.csvData[key].forEach((element) => {
                hash[key].push({ value: element, label: element });
              });
            }
            setMatchWords(hash);
          }
          if (res.data.summaryData) {
            setSummaryData(res.data.summaryData["total"]);
            setTotalSummaryData(res.data.summaryData["total"]);
            setUniqueSummaryData(res.data.summaryData["unique"]);
          }
          if (res.data.rootwordpercentage !== "") {
            setPercentageRootWords(res.data.rootwordpercentage);
          }
          let arrRoot = [];
          let arrNonRoot = [];
          for (const key in response) {
            let incidenceArr = [];
            response[key].wordlist.forEach((wordlistcount) => {
              incidenceArr.push(response[key][wordlistcount]);
            });

            response[key].incidence = incidenceArr;
            let subhash = {};
            subhash["word"] = response[key].originalword;
            subhash["wordcount"] = response[key].wordcount;
            subhash["frequency"] = response[key].frequency;
            subhash["rootword"] = response[key].rootword;
            subhash["wordlist"] = response[key].wordlist;
            subhash["incidence"] = response[key].incidence || [];
            if (response[key].rootword == "true") {
              arrRoot.push(subhash);
            } else if (response[key].rootword == "false") {
              arrNonRoot.push(subhash);
            }
            if (res.data.wordlistCount) {
              setWordListCount(Object.keys(res.data.wordlistCount).length);
            }
          }
          setWordCountArr(arrNonRoot);
          setBookAuthor("");
          setBookNumber("");
          setBookPriority("");
          setFile("");
        })
        .catch((error) => { });
    }
  };

  const getAllWords = (e) => {
    e.preventDefault();
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      setWordCountArr([]);
      if (bookToDisplay !== "") {
        axios
          .get(
            process.env.REACT_APP_API_URL +
            "admin/bookscan/" +
            bookToDisplay.replace(".pdf", ""),
            { headers }
          )
          .then((res) => {
            setTableHeading("All Words ");
            if (res.data.rootwordpercentage !== "") {
              setPercentageRootWords(res.data.rootwordpercentage);
            }
            if (res.data.wordlistCount) {
              setWordListCount(Object.keys(res.data.wordlistCount).length);
            }
            let response = res.data.wordsToCount;
            if (res.data.summaryData) {
              setSummaryData(res.data.summaryData["total"]);
              setTotalSummaryData(res.data.summaryData["total"]);
              setUniqueSummaryData(res.data.summaryData["unique"]);
            }
            let arr = [];
            for (const key in response) {
              let incidenceArr = [];
              response[key].wordlist.forEach((wordlistcount) => {
                incidenceArr.push(response[key][wordlistcount]);
              });

              response[key].incidence = incidenceArr;
              let subhash = {};
              subhash["word"] = response[key].originalword;
              subhash["wordcount"] = response[key].wordcount;
              subhash["frequency"] = response[key].frequency;
              subhash["rootword"] = response[key].rootword;
              subhash["wordlist"] = response[key].wordlist;
              subhash["incidence"] = response[key].incidence || [];
              arr.push(subhash);
            }
            if (res.data.rootwordpercentage) {
              setPercentageRootWords(res.data.rootwordpercentage);
            }
            if (res.data.csvData) {
              let hash = {};
              for (const key in res.data.csvData) {
                hash[key] = [];
                res.data.csvData[key].forEach((element) => {
                  hash[key].push({ value: element, label: element });
                });
              }
              setMatchWords(hash);
            }

            setWordCountArr(arr);
            setBookAuthor("");
            setBookNumber("");
            setBookPriority("");
            setFile("");
          })
          .catch((error) => { });
      }
    }
  };

  const getAllBooks = () => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      fetch(process.env.REACT_APP_API_URL + "books", { headers })
        .then((res) => res.json())
        .then((data) => {
          if (Object.keys(data).length !== 0) {
            let bookarr = [];
            let inprogresscanbook = [];
            let failedscanbook = [];
            let number = 1;
            Object.values(data)[0].book_priority = true;
            for (const eachbook in data) {
              if (data[eachbook].scan_complete_timestamp) {
                bookarr.push(data[eachbook].book_name);
              }
              if (data[eachbook].book_status === "in progress") {
                inprogresscanbook.push(data[eachbook].book_name);
              } else if (data[eachbook].book_status == "failed") {
                failedscanbook.push(data[eachbook].book_name);
              }
              const book = [
                (data[eachbook].number = number),
                // import cover from 'assets/img/covers/Sapiens_cover0.jpg';
                data[eachbook].book_name?.replace(".pdf", ""),
                // <img src={'/covers/' + data[eachbook].book_name.replace(".pdf", "") + '.png'} onError={addDefaultSrc} style={{width: "25px"}}/>,
                data[eachbook].upload_timestamp,
                data[eachbook].scan_complete_timestamp
                  ? "scan " +
                  data[eachbook].book_status +
                  " at " +
                  data[eachbook].scan_complete_timestamp
                  : data[eachbook].book_status,
                data[eachbook].book_isbn_arr &&
                  data[eachbook].book_isbn_arr.length > 0
                  ? data[eachbook].book_isbn_arr.slice(0, -1)
                  : data[eachbook].book_isbn || data[eachbook].book_isbn,
                data[eachbook].book_author || data[eachbook].book_author_arr,
                data[eachbook].startPage || '',
                data[eachbook].endPage || '',
                // <div>
                //   {data[eachbook].book_priority ? (
                //     <Tooltip
                //       data-id={data[eachbook].book_name}
                //       title="Priority Book"
                //       placement="top"
                //       classes={{ tooltip: classes1.tooltip }}
                //       style={{ color: "#FFCD00" }}
                //     >
                //       <RadioButtonChecked sx={{ fontSize: 12 }} />
                //     </Tooltip>
                //   ) : (
                //     <Tooltip
                //       data-id={data[eachbook].book_name}
                //       title="Not a priority Book"
                //       placement="top"
                //       classes={{ tooltip: classes1.tooltip }}
                //       style={{ color: "#34566A" }}
                //     >
                //       <RadioButtonChecked sx={{ fontSize: 12 }} />
                //     </Tooltip>
                //   )}
                // </div>,
                <div>
                  {data[eachbook].scan_complete_timestamp ? (
                    <Tooltip
                      data-id={data[eachbook].book_name}
                      onClick={getBookToScan}
                      title="Rescan Book"
                      placement="top"
                      classes={{ tooltip: classes1.tooltip }}
                    >
                      <IconButton
                        data-id={data[eachbook].book_name}
                        aria-label="Add"
                        className={classes1.tableActionButton}
                      >
                        <DocumentScannerSharp
                          data-id={data[eachbook].book_name}
                          sx={{ fontSize: 16 }}
                          className={
                            classes1.tableActionButtonIcon + " " + classes1.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      data-id={data[eachbook].book_name}
                      onClick={getBookToScan}
                      title="Scan Book"
                      placement="top"
                      classes={{ tooltip: classes1.tooltip }}
                    >
                      <IconButton
                        data-id={data[eachbook].book_name}
                        aria-label="Add"
                        className={classes1.tableActionButton}
                      >
                        <DocumentScannerSharp
                          data-id={data[eachbook].book_name}
                          sx={{ fontSize: 16 }}
                          className={
                            classes1.tableActionButtonIcon + " " + classes1.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  <Tooltip
                    onClick={getBookToEdit}
                    //  onClick={editBookInfo}
                    data-id={data[eachbook].book_name}
                    id={data[eachbook].book_name}
                    title="Edit Book"
                    placement="top"
                    classes={{ tooltip: classes1.tooltip }}
                  >
                    <IconButton
                      aria-label="Edit"
                      className={classes1.tableActionButton}
                    >
                      <Edit
                        id={data[eachbook].book_name}
                        className={
                          classes1.tableActionButtonIcon + " " + classes1.edit
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  {/* 
                <Tooltip
                  onClick={getBookToEdit}
                  //  onClick={editBookInfo}
                  data-id={data[eachbook].book_name} 
                  id={data[eachbook].book_name}
                  title="View Scan Results"
                  placement="top"
                  classes={{tooltip:classes1.tooltip}}>
                  <IconButton aria-label="Edit" className={classes1.tableActionButton}>
                    
                    <Menu id={data[eachbook].book_name} className={classes1.tableActionButtonIcon + " " + classes1.edit}/>
                  </IconButton>
                </Tooltip> */}
                </div>,
              ];
              allUploadedBooksArray.push(book);
              number = number + 1;
            }
            bookarr.forEach((element) => {
              options.push({ value: element, label: element });
            });
            setbooklist(options);
            setAllCompeletedScanBooksArray(bookarr);
            setAllInProgressScanBooksArray(inprogresscanbook);
            setAllFailedScanBooksArray(failedscanbook);
            setAllUploadedBooksArray(allUploadedBooksArray);
          }
        });

    }
  };

  const getbookwordsinfo = async (e) => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      fetch(
        process.env.REACT_APP_API_URL +
        "admin/bookscan/" +
        e.target.textContent.replace(".pdf", ""),
        { headers }
      )
        .then((res) => res.json())
        .then((data) => { });
    }
  };

  const decodeBase64 = (base64data) => {
    let base64ToString = Buffer.from(base64data, "base64").toString();
    return base64ToString;
  };

  const handleSearchChangeContent = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmitContent = (event) => {
    event.preventDefault();
    setParagraph(initialParagraph);
    setSearchTerm("");
    setHighlighted(false);
    const regex = new RegExp("\\b" + searchTerm + "\\b", "gi");
    const updatedParagraph = initialParagraph.replace(
      regex,
      (match) => `<strong style="background-color: yellow;">${match}</strong>`
    );
    setParagraph(updatedParagraph);

    const selection = window.getSelection();
    selection.removeAllRanges();
  };

  const closeChapterDialog = (e) => {
    setShowAddChapterBtn(false);
    setOpenChapterModal(false);
    setRemoveChapter(false);
    setNewChapter("");
    setHighlightedText("");
    setIsInEditChapterMode(false);
  };
  const saveNewChapter = (e) => {
    e.preventDefault();
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      return toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      if (removeChapter == true) {
        const data = {
          highlightedText,
          section: chapterDetail.title,
        };
        axios
          .patch(
            process.env.REACT_APP_API_URL +
            "remove-chapter/" +
            bookToDisplay.replace(".pdf", ""),
            { data },
            { headers }
          )
          .then((res) => {
            let data = res.data;
            if (data.code == 200) {
              toast.success("Successfully removed", {
                position: toast.POSITION.TOP_CENTER,
              });
              setChapterView(data.data);
              setIsVocabularyView(false);
              setShowAddChapterBtn(false);
              setOpenChapterModal(false);
              setRemoveChapter(false);
              setIsInEditChapterMode(false);
              setNewChapter("");
              setChapterDetail({});
              setHighlightedText("");
            }
          })
          .catch((error) => {
            setLoadChapterView(false);
            return toast.error(
              "Something went wrong while trying to remove the data",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
          });
      } else if (isInEditChapterMode == true) {
        if (newChapter == "") {
          return toast.error("Please enter the title of the chapter", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (newContent == "") {
          return toast.error("The content should not be empty", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          const data = {
            section: chapterDetail.title,
            content: chapterDetail.content,
            newChapter,
            newContent,
          };
          axios
            .patch(
              process.env.REACT_APP_API_URL +
              "update-chapter/" +
              bookToDisplay.replace(".pdf", ""),
              { data },
              { headers }
            )
            .then((res) => {
              let data = res.data;
              if (data.code == 200) {
                toast.success("Successfully updated", {
                  position: toast.POSITION.TOP_CENTER,
                });
                setChapterView(data.data);
                setIsVocabularyView(false);
                setShowAddChapterBtn(false);
                setOpenChapterModal(false);
                setRemoveChapter(false);
                setIsInEditChapterMode(false);
                setNewChapter("");
                setChapterDetail({});
                setHighlightedText("");
              }
            })
            .catch((error) => {
              setLoadChapterView(false);
              return toast.error(
                "Something went wrong while trying to update the data",
                {
                  position: toast.POSITION.TOP_CENTER,
                }
              );
            });
        }
      } else {
        if (newChapter == "") {
          return toast.error("Please enter the title of the chapter", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        const data = {
          newChapter,
          highlightedText,
          section: chapterDetail.title,
        };
        axios
          .post(
            process.env.REACT_APP_API_URL +
            "new-chapter/" +
            bookToDisplay.replace(".pdf", ""),
            { data },
            { headers }
          )
          .then((res) => {
            let data = res.data;
            if (data.code == 200) {
              toast.success("Successfully Saved", {
                position: toast.POSITION.TOP_CENTER,
              });
              setChapterView(data.data);
              setIsVocabularyView(false);
              setShowAddChapterBtn(false);
              setOpenChapterModal(false);
              setRemoveChapter(false);
              setNewChapter("");
              setChapterDetail({});
              setIsInEditChapterMode(false);
              setHighlightedText("");
            }
          })
          .catch((error) => {
            setLoadChapterView(false);
            return toast.error(
              "Something went wrong while trying to save the data",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
          });
      }
    }
  };

  function handleHighlight() {
    const selection = window.getSelection();
    const selectedText = selection.toString();

    if (selectedText.trim().length > 0) {
      setHighlightedText(selectedText);
    }
    // const selection = window.getSelection();
    // const text = selection.toString();
    // setHighlightedText(text);
    setShowAddChapterBtn(true);
  }
  const getChapterDetail = (chapter) => {
    const filter = chapterView.filter((el) => el.section == chapter);
    setChapterDetail({ content: filter[0].content, title: chapter });
    setInitialParagraph(filter[0].content);
    setParagraph(filter[0].content);
  };
  const getChapterView = (e) => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      return toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (bookToDisplay == "") {
      return toast.error("Please select the name of the book", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      setLoadChapterView(true);
      axios
        .get(
          process.env.REACT_APP_API_URL +
          "book-chapters/" +
          bookToDisplay.replace(".pdf", ""),
          { headers }
        )
        .then((res) => {
          let data = res.data;
          setLoadChapterView(false);
          if (data.code == 200) {
            setTableView("Chapter View ");
            setChapterView(data.data);
            setIsVocabularyView(false);
            setChapterDetail({});
            setIsInEditChapterMode(false);
          }
        })
        .catch((error) => {
          setLoadChapterView(false);
          return toast.error(
            "Something went wrong while trying to fetch the data",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        });
    }
  };
  const setSelectedOptionFunc = (book) => {
    localStorage.removeItem("inputValue");
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      setSelectedOption;
      setBookToDisplay(book);
      setWordCountArr([]);
      setSummaryData({});
      setWordListCount("");
      setLoadChapterView(true);
      setChapterDetail({});
      setIsVocabularyView(true);
      axios
        .get(
          process.env.REACT_APP_API_URL +
          "admin/bookscan/" +
          book.replace(".pdf", ""),
          { headers }
        )
        .then((res) => {
          // console.log(res.data.cover);
          // console.log(d);
          setCover(res.data.cover);
          setTableView("Vocabulary View ");
          setTableHeading("View as Head Words ");
          setLoadChapterView(false);
          let response = res.data.wordsToCount;
          if (res.data.csvData) {
            let hash = {};
            for (const key in res.data.csvData) {
              hash[key] = [];
              res.data.csvData[key].forEach((element) => {
                hash[key].push({ value: element, label: element });
              });
            }
            setMatchWords(hash);
          }
          if (res.data.summaryData) {
            setSummaryData(res.data.summaryData["total"]);
            setTotalSummaryData(res.data.summaryData["total"]);
            setUniqueSummaryData(res.data.summaryData["unique"]);
          }
          if (res.data.rootwordpercentage !== "") {
            setPercentageRootWords(res.data.rootwordpercentage);
          }
          let arrHead = [];

          for (const key in response) {
            let incidenceArr = [];
            response[key].wordlist.forEach((wordlistcount) => {
              incidenceArr.push(response[key][wordlistcount]);
            });

            response[key].incidence = incidenceArr;
            let subhash = {};
            subhash["wordcount"] =
              response[key].wordfamilycount || response[key].wordcount;
            subhash["frequency"] = response[key].frequency;
            subhash["rootword"] = response[key].rootword;
            subhash["wordlist"] = response[key].wordlist;
            subhash["incidence"] = response[key].incidence || [];
            subhash["word"] =
              response[key].headWord !== undefined &&
                response[key].frequency != "0"
                ? response[key].headWord
                : response[key].originalword;
            arrHead.push(subhash);

            if (res.data.wordlistCount) {
              setWordListCount(Object.keys(res.data.wordlistCount).length);
            }
          }
          const uniqueIds = [];

          const unique = arrHead.filter((element) => {
            const isDuplicate = uniqueIds.includes(element.word);

            if (!isDuplicate) {
              uniqueIds.push(element.word);

              return true;
            }

            return false;
          });
          setWordCountArr(unique);
          setBookAuthor("");
          setBookNumber("");
          setBookPriority("");
          setFile("");
        })
        .catch((error) => {
          setLoadChapterView(false);
        });
    }
  };

  const setMatchOptionFunc = (e) => {
    setMatchOption;
    // setBookToDisplay(e.value)
  };

  useEffect(() => {
    getAllBooks();
  }, []);

  const handleFileBtnClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    // console.log(e.dataTransfer.files[0]);
    setFile(e.dataTransfer.files[0]);
  };

  const handleFileChange = (e) => {
    const bookfile = e.target.files && e.target.files[0];
    if (!bookfile) return;
    setFile(e.target.files[0]);
  };

  const uploadBookToServer = (e) => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (bookauthor == "") {
      return toast.error("The author's name cannot be empty", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      setLoadingUpload(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      e.preventDefault();
      if (bookLink !== "" && bookauthor !== "") {
        if (bookLink.slice(-4).toLowerCase() != "html") {
          setLoadingUpload(false)
          return toast.error(
            "Please enter the html URL of a book from the Gutenberg site. Make sure you choose the HTML5 format",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        } else {
          const data = {
            bookLink,
            bookauthor,
            bookPriority,
            bookNumber,
            startPage,
            endPage
          };
          axios
            .post(
              process.env.REACT_APP_API_URL + "admin/bookLink",
              { data },
              {
                headers,
              }
            )
            .then((response) => {
              setLoadingUpload(false);
              if (response.data.code === 200) {
                toast.success("Successfully uploaded book", {
                  position: toast.POSITION.TOP_CENTER,
                });
              } else {
                toast.error(
                  "Something went wrong while trying to fetch the html of the book",
                  {
                    position: toast.POSITION.TOP_CENTER,
                  }
                );
              }

              setBookAuthor("");
              setBookNumber("");
              setBookPriority("");
              setFile("");
              setBookToEdit("");
              window.location.reload(false);
            })
            .catch((error) => {
              setLoadingUpload(false);
              return toast.error(
                "Something went wrong while trying to fetch the html of the book",
                {
                  position: toast.POSITION.TOP_CENTER,
                }
              );
            });
        }
      } else {
        let myformData = new FormData();
        myformData.append("book", file);
        myformData.append("bookauthor", bookauthor);
        myformData.append("bookNumber", bookNumber);
        myformData.append("startPage", startPage);
        myformData.append("endPage", endPage);
        // myformData.append("bookPriority", bookPriority);
        let bookinfo = {};
        for (var [key, value] of myformData.entries()) {
          bookinfo[key] = value;
        }
        axios
          .post(process.env.REACT_APP_API_URL + "admin/bookscan", myformData, {
            headers,
          })
          .then((response) => {
            setLoadingUpload(false);
            toast.success("Successfully uploaded book", {
              position: toast.POSITION.TOP_CENTER,
            });
            setBookAuthor("");
            setBookNumber("");
            setBookPriority("");
            setFile("");
            setBookToEdit("");
            window.location.reload(false);
          })
          .catch((error) => {
            setLoadingUpload(false);
            if (file === "") {
              toast.error("please add a book file");
            } else {
              toast.error("upload fail");
            }
          });
      }
      setFiles([]);
    }
  };

  return (
    <div>
      {currentUser.role === "Super Admin" ? (
        <div>
          {/* <Header /> */}
          <div className="form-group">
            <ToastContainer />
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CustomTabs
                headerColor="primary"
                tabs={[
                  {
                    tabName: "Upload",
                    tabIcon: BackupRounded,
                    tabContent: (
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          style={{ padding: "7px 3px" }}
                        >
                          {/* Dialogs */}
                          <div>
                            {/* Start of Scan Book Dialogue */}
                            <Dialog
                              classes={{
                                root: classes.center,
                                paper: classes.modal,
                              }}
                              open={modal1}
                              TransitionComponent={Transition}
                              keepMounted
                              onClose={() => setModal1(false)}
                              aria-labelledby="modal-slide-title"
                              aria-describedby="modal-slide-description"
                            >
                              <DialogTitle
                                id="classic-modal-slide-title"
                                disableTypography
                                className={classes.modalHeader}
                              >
                                <IconButton
                                  className={classes.modalCloseButton}
                                  key="close"
                                  aria-label="Close"
                                  color="inherit"
                                  onClick={() => setModal1(false)}
                                >
                                  <Close className={classes.modalClose} />
                                </IconButton>
                                <h4
                                  style={{ width: "20rem", margin: "auto" }}
                                  className={classes.modalTitle}
                                >
                                  Scan this book
                                </h4>
                              </DialogTitle>
                              <DialogContent
                                id="modal-slide-description"
                                className={classes.modalBody}
                              >
                                Are you sure you want to scan the book,{" "}
                                {booktoedit}?
                              </DialogContent>
                              <DialogActions
                                className={
                                  classes.modalFooter +
                                  " " +
                                  classes.modalFooterCenter
                                }
                              >
                                <Button color="primary" onClick={scanBook}>
                                  Scan Book
                                </Button>
                              </DialogActions>
                            </Dialog>

                            {/* End of Scan Book Dialogue */}

                            {/* Start of Edit Book Dialog */}
                            <Dialog
                              classes={{
                                root: classes.center,
                                paper: classes.modal,
                              }}
                              open={modal}
                              TransitionComponent={Transition}
                              keepMounted
                              onClose={() => setModal(false)}
                              aria-labelledby="modal-slide-title"
                              aria-describedby="modal-slide-description"
                            >
                              <DialogTitle
                                id="classic-modal-slide-title"
                                disableTypography
                                className={classes.modalHeader}
                              >
                                <IconButton
                                  className={classes.modalCloseButton}
                                  key="close"
                                  aria-label="Close"
                                  color="inherit"
                                  onClick={() => setModal(false)}
                                >
                                  <Close className={classes.modalClose} />
                                </IconButton>
                                <h4
                                  style={{ width: "20rem", margin: "auto" }}
                                  className={classes.modalTitle}
                                >
                                  Edit this book info
                                </h4>
                              </DialogTitle>
                              <DialogContent
                                id="modal-slide-description"
                                className={classes.modalBody}
                              >
                                <form id="bookForm" name="bookForm">
                                  <CustomInput
                                    formControlProps={{
                                      style: { width: "20rem", margin: "auto" },
                                      className:
                                        classes.margin + " " + classes.search,
                                    }}
                                    labelText={
                                      "Current Title: " + bookTitle
                                    }
                                    type="text"
                                    id="float"
                                    name="title"
                                    value={bookTitle}
                                    inputProps={{
                                      value: bookTitle,
                                      onChange: (e) =>
                                        setBookTitle(e.target.value),
                                    }}
                                  />
                                  <br />
                                  <CustomInput
                                    formControlProps={{
                                      style: { width: "20rem", margin: "auto" },
                                      className:
                                        classes.margin + " " + classes.search,
                                    }}
                                    labelText={
                                      "Current Author: " + previousbookauthor
                                    }
                                    type="text"
                                    id="float"
                                    name="author"
                                    value={newbookauthor}
                                    inputProps={{
                                      value: newbookauthor,
                                      onChange: (e) =>
                                        setNewBookAuthor(e.target.value),
                                    }}
                                  />
                                  <br />
                                  <CustomInput
                                    formControlProps={{
                                      style: { width: "20rem", margin: "auto" },
                                      className:
                                        classes.margin + " " + classes.search,
                                    }}
                                    labelText={
                                      "Current ISBN: " + previousbookNumber
                                    }
                                    id="float"
                                    name="isbn"
                                    type="number"
                                    inputProps={{
                                      value: newbookNumber,
                                      onChange: (e) =>
                                        setNewBookNumber(e.target.value),
                                    }}
                                  />
                                  <br />
                                  {/* <CustomInput
                                    formControlProps={{
                                      style: { width: "20rem", margin: "auto" },
                                      className:
                                        classes.margin + " " + classes.search,
                                    }}
                                    labelText={
                                      "Current Priority: " + previouspriority
                                    }
                                    id="float"
                                    name="priority"
                                    min="1"
                                    max="10"
                                    type="number"
                                    inputProps={{
                                      value: newpriority,
                                      min: 1,
                                      max: 10,
                                      type: "number",
                                      onChange: (e) =>
                                        setNewPriority(e.target.value),
                                    }}
                                  /> */}
                                  <Grid container  >
                                    <Grid item xs={5} sm={5} md={5} lg={5} sx={{ paddingRight: '5px' }}>
                                      <CustomInput
                                        formControlProps={{
                                          className:
                                            classes.margin +
                                            " " +
                                            classes.search,
                                        }}
                                        labelText={
                                          "Start Page: "
                                        }
                                        id="int"
                                        name="startPage"
                                        type="number"
                                        inputProps={{
                                          value: startPage,
                                          type: "number",
                                          onChange: (e) =>
                                            setStartPage(
                                              e.target.value
                                            ),
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={5} sm={5} md={5} lg={5}>
                                      <CustomInput
                                        formControlProps={{
                                          className:
                                            classes.margin +
                                            " " +
                                            classes.search,
                                        }}
                                        labelText={
                                          "End Page: "
                                        }
                                        id="int"
                                        name="endPage"
                                        type="number"
                                        inputProps={{
                                          value: endPage,
                                          type: "number",
                                          onChange: (e) =>
                                            setEndPage(
                                              e.target.value
                                            ),
                                        }}
                                      />
                                    </Grid></Grid>
                                  <br />
                                  <br />
                                </form>
                              </DialogContent>
                              <DialogActions
                                className={
                                  classes.modalFooter +
                                  " " +
                                  classes.modalFooterCenter
                                }
                              >
                                <Button color="primary" onClick={editBookInfo}>
                                  Edit Book
                                </Button>
                              </DialogActions>
                            </Dialog>
                            {/* End of Edit Book Dialog */}

                            {/* Start of Word count Dialogue */}
                            <Dialog
                              classes={{
                                root: classes.center,
                                paper: classes.modal,
                              }}
                              open={modal2}
                              TransitionComponent={Transition}
                              keepMounted
                              onClose={() => setModal2(false)}
                              aria-labelledby="modal-slide-title"
                              aria-describedby="modal-slide-description"
                            >
                              <DialogTitle
                                id="classic-modal-slide-title"
                                disableTypography
                                className={classes.modalHeader}
                              >
                                <IconButton
                                  className={classes.modalCloseButton}
                                  key="close"
                                  aria-label="Close"
                                  color="inherit"
                                  onClick={() => setModal2(false)}
                                >
                                  <Close className={classes.modalClose} />
                                </IconButton>
                                <h4
                                  style={{ width: "20rem", margin: "auto" }}
                                  className={classes.modalTitle}
                                >
                                  Generate Book Wordcount Table
                                </h4>
                              </DialogTitle>
                              <DialogContent
                                id="modal-slide-description"
                                className={classes.modalBody}
                              >
                                <p>
                                  Are you sure you want to generate word count
                                  table?
                                </p>
                              </DialogContent>
                              <DialogActions
                                className={
                                  classes.modalFooter +
                                  " " +
                                  classes.modalFooterCenter
                                }
                              >
                                <Button
                                  onClick={getbookwordsinfo}
                                  color="success"
                                >
                                  Get WordCount
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </div>

                          {/* Upload Book Page */}
                          <CustomTabs
                            title=""
                            headerColor="primary"
                            showShadow={true}
                            tabs={[
                              {
                                tabName: "Upload Book(s)",
                                tabContent: (
                                  <Grid
                                    container
                                    spacing={{ md: 2 }}
                                    justifyContent="center"
                                  >
                                    {/* <Grid item sm={2} md={3}></Grid> */}
                                    <Grid item xs={12} sm={8} md={6} lg={4}>
                                      <Card
                                        style={{
                                          width: "100%",
                                          margin: "1rem auto",
                                          backgroundColor: "#34566A",
                                          boxShadow: "none",
                                          border: "1px solid #34566A",
                                          borderRadius: "6px",
                                          color: "#FFFFFF",
                                        }}
                                      >
                                        <CardBody>
                                          <div
                                            style={{
                                              padding: "18px 10px 0 10px",
                                              boxShadow: "none",
                                              borderRadius: "0px",
                                              textAlign: "center",
                                              verticalAlign: "middle",
                                              fontWeight: 700,
                                              fontSize: "1.1rem",
                                              lineHeight: "15px",
                                              color: "#FFFFFF",
                                            }}
                                          >
                                            Upload Book(s) to Scan
                                          </div>
                                          <form
                                            id="bookForm"
                                            name="bookForm"
                                            style={{ paddingBottom: "15px" }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: "0px 18px",
                                                marginTop: "28px",
                                                fontSize: "14px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              <CustomInput
                                                formControlProps={{
                                                  className: classes.search,
                                                }}
                                                whiteLabelRoot={true}
                                                labelText="Author"
                                                type="text"
                                                id="float"
                                                name="author"
                                                value={bookauthor}
                                                inputProps={{
                                                  value: bookauthor,
                                                  onChange: (e) =>
                                                    setBookAuthor(
                                                      e.target.value
                                                    ),
                                                }}
                                              />

                                              <CustomInput
                                                formControlProps={{
                                                  className:
                                                    classes.margin +
                                                    " " +
                                                    classes.search,
                                                }}
                                                whiteLabelRoot={true}
                                                labelText="ISBN"
                                                id="float"
                                                name="isbn"
                                                type="number"
                                                inputProps={{
                                                  value: bookNumber,
                                                  onChange: (e) =>
                                                    setBookNumber(
                                                      e.target.value
                                                    ),
                                                }}
                                              />
                                              <Grid
                                                container

                                                justifyContent="center"
                                              >
                                                {/* <Grid item sm={2} md={3}></Grid> */}
                                                <Grid item xs={6} sm={6} md={6} lg={6} sx={{ paddingRight: '5px' }}>
                                                  <CustomInput
                                                    formControlProps={{
                                                      className:
                                                        classes.margin +
                                                        " " +
                                                        classes.search,
                                                    }}
                                                    labelText="Start Page"
                                                    whiteLabelRoot={true}
                                                    id="int"
                                                    name="startPage"
                                                    type="number"
                                                    inputProps={{
                                                      value: startPage,
                                                      type: "number",
                                                      onChange: (e) =>
                                                        setStartPage(
                                                          e.target.value
                                                        ),
                                                    }}
                                                  />
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                                  <CustomInput
                                                    formControlProps={{
                                                      className:
                                                        classes.margin +
                                                        " " +
                                                        classes.search,
                                                    }}
                                                    labelText="End Page"
                                                    whiteLabelRoot={true}
                                                    id="int"
                                                    name="endPage"
                                                    type="number"
                                                    inputProps={{
                                                      value: endPage,
                                                      type: "number",
                                                      onChange: (e) =>
                                                        setEndPage(
                                                          e.target.value
                                                        ),
                                                    }}
                                                  />
                                                </Grid>
                                              </Grid>
                                              {/* <CustomInput
                                                formControlProps={{
                                                  className:
                                                    classes.margin +
                                                    " " +
                                                    classes.search,
                                                }}
                                                labelText="Priority Number"
                                                whiteLabelRoot={true}
                                                id="float"
                                                name="priority"
                                                min="1"
                                                max="10"
                                                type="number"
                                                inputProps={{
                                                  value: bookPriority,
                                                  min: 1,
                                                  max: 10,
                                                  type: "number",
                                                  onChange: (e) =>
                                                    setBookPriority(
                                                      e.target.value
                                                    ),
                                                }}
                                              /> */}
                                              <CustomInput
                                                formControlProps={{
                                                  className: classes.search,
                                                }}
                                                whiteLabelRoot={true}
                                                labelText="HTML URL from the Gutenberg site"
                                                type="text"
                                                name="bookLink"
                                                value={bookLink}
                                                inputProps={{
                                                  value: bookLink,
                                                  onChange: (e) =>
                                                    setBookLink(e.target.value),
                                                }}
                                              />
                                              <br />
                                              <br />
                                              <div
                                                style={{
                                                  borderRadius: "5px",
                                                  maxHeight: "121px",
                                                  height: "100%",
                                                  border: "2px dashed #FFFFFF",
                                                  textAlign: "center",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    flexDirection: "column",
                                                    color: "#C4C4C4",
                                                    paddingTop: "10px",
                                                    paddingBottom: "17px",
                                                  }}
                                                  onDragOver={handleDragOver}
                                                  onDrop={handleDrop}
                                                >
                                                  <span>Drag &amp; drop</span>
                                                  <span>Or</span>

                                                  <span>
                                                    <input
                                                      style={{
                                                        display: "none",
                                                      }}
                                                      type="file"
                                                      name="book"
                                                      className="custom-file-input"
                                                      id="customFile"
                                                      ref={fileInputRef}
                                                      onChange={
                                                        handleFileChange
                                                      }
                                                      required
                                                    />
                                                    <Button
                                                      type="button"
                                                      color="primary"
                                                      style={{
                                                        border:
                                                          "1px solid white",
                                                        maxWidth: "110px",
                                                        width: "100%",
                                                        margin: "0 auto",
                                                        borderRadius: "3px",
                                                        textTransform: "none",
                                                        color: "#bbb",
                                                      }}
                                                      onClick={
                                                        handleFileBtnClick
                                                      }
                                                    >
                                                      <Folder
                                                        sx={{ fontSize: 40 }}
                                                      />
                                                      Browse
                                                    </Button>
                                                    <span
                                                      style={{
                                                        display:
                                                          file !== ""
                                                            ? "inline-block"
                                                            : "none",
                                                        marginLeft: "5px",
                                                      }}
                                                    >
                                                      {file.name}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <br />
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Button
                                                color="secondary"
                                                onClick={uploadBookToServer}
                                                startIcon={
                                                  <FileUploadOutlined
                                                    fontSize={"14px"}
                                                  />
                                                }
                                              >
                                                Upload
                                              </Button>
                                            </div>
                                            {loadingUpload && (
                                              <BeatLoader
                                                color={"#BB2BFF"}
                                                loading={setLoadingUpload}
                                                size={30}
                                              />
                                            )}
                                          </form>
                                        </CardBody>
                                      </Card>
                                    </Grid>
                                    {/* <Grid item sm={2} md={3}></Grid> */}
                                  </Grid>
                                ),
                              },
                              {
                                tabName: "All Uploaded Books",
                                tabContent: (
                                  <div>
                                    <Table
                                      tableHeaderColor="primary"
                                      tableHead={[
                                        "Number",
                                        "Book Title",
                                        "Uploaded Time",
                                        "Book Status",
                                        "ISBN",
                                        "Author",
                                        "startPage",
                                        "endPage",
                                        "Scan/Edit",
                                      ]}
                                      tableData={allUploadedBooksArray}
                                    />
                                    {/* <Button
                                      color="primary"
                                      onClick={getAllISBN}
                                    >
                                      Get all ISBN # and Available book covers
                                    </Button> */}
                                  </div>
                                ),
                              },
                            ]}
                          ></CustomTabs>
                        </Grid>
                      </Grid>
                    ),
                  },

                  {
                    tabName: "Scans",
                    tabIcon: Book,
                    tabContent: (
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ padding: "7px 3px" }}
                      >
                        <div>
                          <Dialog
                            classes={{
                              root: classes.center,
                              paper: classes.modal,
                            }}
                            open={modal1}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={() => setModal1(false)}
                            aria-labelledby="modal-slide-title"
                            aria-describedby="modal-slide-description"
                          >
                            <DialogTitle
                              id="classic-modal-slide-title"
                              disableTypography
                              className={classes.modalHeader}
                            >
                              <IconButton
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={() => setModal1(false)}
                              >
                                <Close className={classes.modalClose} />
                              </IconButton>
                              <h4
                                style={{ width: "20rem", margin: "auto" }}
                                className={classes.modalTitle}
                              >
                                Scan this book
                              </h4>
                            </DialogTitle>
                            <DialogContent
                              id="modal-slide-description"
                              className={classes.modalBody}
                            >
                              Are you sure you want to scan the book,{" "}
                              {booktoedit}?
                            </DialogContent>
                            <DialogActions
                              className={
                                classes.modalFooter +
                                " " +
                                classes.modalFooterCenter
                              }
                            >
                              <Button color="secondary" onClick={scanBook}>
                                Scan Book
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <Dialog
                            classes={{
                              root: classes.center,
                              paper: classes.modal,
                            }}
                            open={modal}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={() => setModal(false)}
                            aria-labelledby="modal-slide-title"
                            aria-describedby="modal-slide-description"
                          >
                            <DialogTitle
                              id="classic-modal-slide-title"
                              disableTypography
                              className={classes.modalHeader}
                            >
                              <IconButton
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={() => setModal(false)}
                              >
                                <Close className={classes.modalClose} />
                              </IconButton>
                              <h4
                                style={{ width: "20rem", margin: "auto" }}
                                className={classes.modalTitle}
                              >
                                Edit this book info
                              </h4>
                            </DialogTitle>
                            <DialogContent
                              id="modal-slide-description"
                              className={classes.modalBody}
                            >
                              <form id="bookForm" name="bookForm">
                                <CustomInput
                                  formControlProps={{
                                    style: { width: "20rem", margin: "auto" },
                                    className:
                                      classes.margin + " " + classes.search,
                                  }}
                                  labelText={
                                    "Current Author: " + previousbookauthor
                                  }
                                  type="text"
                                  id="float"
                                  name="author"
                                  value={newbookauthor}
                                  inputProps={{
                                    value: newbookauthor,
                                    onChange: (e) =>
                                      setNewBookAuthor(e.target.value),
                                  }}
                                />
                                <br />
                                <CustomInput
                                  formControlProps={{
                                    style: { width: "20rem", margin: "auto" },
                                    className:
                                      classes.margin + " " + classes.search,
                                  }}
                                  labelText={
                                    "Current ISBN: " + previousbookNumber
                                  }
                                  id="float"
                                  name="isbn"
                                  type="number"
                                  inputProps={{
                                    value: newbookNumber,
                                    onChange: (e) =>
                                      setNewBookNumber(e.target.value),
                                  }}
                                />
                                <br />
                                <CustomInput
                                  formControlProps={{
                                    style: { width: "20rem", margin: "auto" },
                                    className:
                                      classes.margin + " " + classes.search,
                                  }}
                                  labelText={
                                    "Current Priority: " + previouspriority
                                  }
                                  id="float"
                                  name="priority"
                                  min="1"
                                  max="10"
                                  type="number"
                                  inputProps={{
                                    value: newpriority,
                                    min: 1,
                                    max: 10,
                                    type: "number",
                                    onChange: (e) =>
                                      setNewPriority(e.target.value),
                                  }}
                                />
                                <br />
                                <br />
                              </form>
                            </DialogContent>
                            <DialogActions
                              className={
                                classes.modalFooter +
                                " " +
                                classes.modalFooterCenter
                              }
                            >
                              <Button color="primary" onClick={editBookInfo}>
                                Edit Book
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <Dialog
                            classes={{
                              root: classes.center,
                              paper: classes.modal,
                            }}
                            open={modal2}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={() => setModal2(false)}
                            aria-labelledby="modal-slide-title"
                            aria-describedby="modal-slide-description"
                          >
                            <DialogTitle
                              id="classic-modal-slide-title"
                              disableTypography
                              className={classes.modalHeader}
                            >
                              <IconButton
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={() => setModal2(false)}
                              >
                                <Close className={classes.modalClose} />
                              </IconButton>
                              <h4
                                style={{ width: "20rem", margin: "auto" }}
                                className={classes.modalTitle}
                              >
                                Generate Book Wordcount Table
                              </h4>
                            </DialogTitle>
                            <DialogContent
                              id="modal-slide-description"
                              className={classes.modalBody}
                            >
                              <p>
                                Are you sure you want to generate word count
                                table?
                              </p>
                            </DialogContent>
                            <DialogActions
                              className={
                                classes.modalFooter +
                                " " +
                                classes.modalFooterCenter
                              }
                            >
                              <Button
                                onClick={getbookwordsinfo}
                                color="success"
                              >
                                Get WordCount
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </div>
                        <CustomTabs
                          title=""
                          showShadow={true}
                          headerColor="primary"
                          tabs={[
                            {
                              tabName: "Completed Scans",
                              tabContent: (
                                <div
                                  style={{
                                    padding: "14px 15px 0 15px",
                                    overflowX: "auto",
                                  }}
                                >
                                  <Select
                                    defaultValue={selectedOption}
                                    // onChange={setSelectedOptionFunc}
                                    onChange={(e) => {
                                      setSelectedOptionFunc(e.value);
                                    }}
                                    options={booklist}
                                  />

                                  <br />
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={`${cover}`}
                                          onError={addDefaultSrc}
                                          style={{
                                            width: "65px",
                                            marginRight: "3px",
                                          }}
                                        />
                                        <div>
                                          <p className={classes1.tableHeadCell}>
                                            {bookToDisplay.replace(".pdf", "")}
                                          </p>
                                          {percentageRootWords === "" ? (
                                            ""
                                          ) : (
                                            <p>
                                              (This book has{" "}
                                              <span className={classes1.strong}>
                                                {percentageRootWords}% root
                                                words
                                              </span>{" "}
                                              from{" "}
                                              <span className={classes1.strong}>
                                                {wordListCount} word families
                                              </span>
                                              )
                                            </p>
                                          )}{" "}
                                        </div>
                                      </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={2}>
                                      {loadChapterView && (
                                        <DotLoader
                                          color={"#34566A"}
                                          loading={loadChapterView}
                                          size={60}
                                        />
                                      )}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                      {bookToDisplay && (
                                        <div style={{ float: "right" }}>
                                          <DropdownButton
                                            variant="warning"
                                            title={tableView}
                                          >
                                            <Dropdown.Item
                                              onClick={(e) => {
                                                setSelectedOptionFunc(
                                                  bookToDisplay
                                                );
                                              }}
                                            >
                                              Vocabulary View
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={getChapterView}
                                            >
                                              Chapter View
                                            </Dropdown.Item>
                                          </DropdownButton>
                                        </div>
                                      )}
                                    </GridItem>
                                  </GridContainer>
                                  <br />
                                  {isVocabularyView ? (
                                    <div>
                                      <div
                                        style={{
                                          fontSize: "14px",
                                          textAlign: "center",
                                          fontFamily: "Poppins",
                                          color: "#808080",
                                          fontWeight: 600,
                                          padding: "5px",
                                          marginBottom: "20px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "#303030",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Summary
                                        </span>
                                        {summaryData === totalSummaryData ? (
                                          <span
                                            onClick={() =>
                                              setSummaryData(uniqueSummaryData)
                                            }
                                            data-hover="button"
                                          >
                                            {" "}
                                            <style>{`[data-hover="button"]:hover {
        cursor: pointer !important; color: #34566A;
    }`}</style>{" "}
                                            (View Unique Count)
                                          </span>
                                        ) : (
                                          <span
                                            onClick={() => {
                                              getHeadWords;
                                              setSummaryData(totalSummaryData);
                                            }}
                                            data-hover="button"
                                          >
                                            {" "}
                                            <style>{`[data-hover="button"]:hover {
        cursor: pointer !important; color: #34566A;
    }`}</style>
                                            (View Total Count)
                                          </span>
                                        )}
                                      </div>
                                      <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                          <table
                                            {...getTableProps()}
                                            style={{
                                              border: "solid 1px blue",
                                              width: "100%",
                                            }}
                                          >
                                            <thead>
                                              {headerGroups.map(
                                                (headerGroup) => (
                                                  <tr
                                                    {...headerGroup.getHeaderGroupProps()}
                                                  >
                                                    {headerGroup.headers.map(
                                                      (column) => (
                                                        <th
                                                          {...column.getHeaderProps()}
                                                          style={{
                                                            border:
                                                              "solid 1px gray",
                                                            background:
                                                              "rgba(52, 86, 106, 0.25)",
                                                            color: "black",
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          {column.render(
                                                            "Header"
                                                          )}
                                                        </th>
                                                      )
                                                    )}
                                                  </tr>
                                                )
                                              )}
                                            </thead>
                                            <tbody {...getTableBodyProps()}>
                                              <tr>
                                                <td style={cellStyle1}>
                                                  {summaryData ===
                                                    totalSummaryData
                                                    ? "Total "
                                                    : "Unique "}
                                                  Count
                                                </td>
                                                <td style={cellStyle}>
                                                  {summaryData["WordsCount"]}
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "LessThan4LetterWords"
                                                    ]
                                                  }
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "Between5And7LetterWords"
                                                    ]
                                                  }
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "Between8And10LetterWords"
                                                    ]
                                                  }
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "GreaterThan10LetterWords"
                                                    ]
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={cellStyle1}>
                                                  Root Words Count
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "RootWordsCount"
                                                    ]
                                                  }
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "LessThan4LetterRootWords"
                                                    ]
                                                  }
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "Between5And7LetterRootWords"
                                                    ]
                                                  }
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "Between8And10LetterRootWords"
                                                    ]
                                                  }
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "GreaterThan10LetterRootWords"
                                                    ]
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={cellStyle1}>
                                                  Non Root Words Count
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "NonRootWordsCount"
                                                    ]
                                                  }
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "LessThan4LetterNonRootWords"
                                                    ]
                                                  }
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "Between5And7LetterNonRootWords"
                                                    ]
                                                  }
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "Between8And10LetterNonRootWords"
                                                    ]
                                                  }
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "GreaterThan10LetterNonRootWords"
                                                    ]
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={cellStyle1}>
                                                  %{" "}
                                                  {summaryData ===
                                                    totalSummaryData
                                                    ? "Total "
                                                    : "Unique "}{" "}
                                                  Words
                                                </td>
                                                <td style={cellStyle}>
                                                  {summaryData["WordsPercent"]}%
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "LessThan4LetterWordsPercent"
                                                    ]
                                                  }
                                                  %
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "Between5And7LetterWordsPercent"
                                                    ]
                                                  }
                                                  %
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "Between8And10LetterWordsPercent"
                                                    ]
                                                  }
                                                  %
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "GreaterThan10LetterWordsPercent"
                                                    ]
                                                  }
                                                  %
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={cellStyle1}>
                                                  % Root Words
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "RootWordsPercent"
                                                    ]
                                                  }
                                                  %
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "LessThan4LetterRootWordsPercent"
                                                    ]
                                                  }
                                                  %
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "Between5And7LetterRootWordsPercent"
                                                    ]
                                                  }
                                                  %
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "Between8And10LetterRootWordsPercent"
                                                    ]
                                                  }
                                                  %
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "GreaterThan10LetterRootWordsPercent"
                                                    ]
                                                  }
                                                  %
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={cellStyle1}>
                                                  % Non-Root Words
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "NonRootWordsPercent"
                                                    ]
                                                  }
                                                  %
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "LessThan4LetterNonRootWordsPercent"
                                                    ]
                                                  }
                                                  %
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "Between5And7LetterNonRootWordsPercent"
                                                    ]
                                                  }
                                                  %
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "Between8And10LetterNonRootWordsPercent"
                                                    ]
                                                  }
                                                  %
                                                </td>
                                                <td style={cellStyle}>
                                                  {
                                                    summaryData[
                                                    "GreaterThan10LetterNonRootWordsPercent"
                                                    ]
                                                  }
                                                  %
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </GridItem>
                                      </GridContainer>
                                      <br />
                                      <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                          <DropdownButton
                                            variant="warning"
                                            title={tableHeading}
                                          >
                                            <Dropdown.Item
                                              onClick={getAllWords}
                                            >
                                              View All Words
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={getHeadWords}
                                            >
                                              View as Head Words
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={getRootWords}
                                            >
                                              View Root Words
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={getNonRootWords}
                                            >
                                              View Non Root Words
                                            </Dropdown.Item>
                                          </DropdownButton>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={2}>
                                          {loadStudyList && (
                                            <BeatLoader
                                              color={"rgba(0, 0, 0, 0.97)"}
                                              loading={loadStudyList}
                                              size={20}
                                            />
                                          )}
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                          <Button
                                            color="secondary"
                                            onClick={masterStudyList}
                                          >
                                            {/* <Link to={`/admin/coredata/${bookToDisplay}`}>Create Study List</Link> */}
                                            Create Master Study Lists
                                          </Button>
                                        </GridItem>
                                      </GridContainer>
                                      <br />
                                      <ReactTable
                                        style={{
                                          backgroundColor: "#fff",
                                          width: "40px",
                                          overflowX: "auto",
                                        }}
                                        columns={columns1}
                                        data={wordData}
                                      />
                                      <div
                                        style={{
                                          position: "sticky",
                                          bottom: "5px",
                                          backgroundColor: "transperent",
                                          width: "100%",
                                        }}
                                      >
                                        <Button
                                          color="primary"
                                          onClick={(e) => {
                                            saveSelection("", "", 2);
                                          }}
                                        >
                                          Save Selection
                                        </Button>
                                        <Button
                                          onClick={deletedWords}
                                          color="danger"
                                        >
                                          Delete Selection
                                        </Button>
                                      </div>
                                      <Dialog
                                        open={openModalMatch}
                                        onClose={() => setOpenModalMatch(false)}
                                        aria-labelledby="modal-slide-title"
                                        aria-describedby="modal-slide-description"
                                      >
                                        <DialogTitle
                                          id="classic-modal-slide-title"
                                          style={{ textAlign: "center" }}
                                        >
                                          Are you sure you want to save these
                                          matches from{" "}
                                          <strong>
                                            {bookToDisplay && bookToDisplay}
                                          </strong>{" "}
                                          ?
                                        </DialogTitle>
                                        <DialogContent>
                                          {inputValues
                                            ? inputValues.map(
                                              (array, index) => (
                                                <div
                                                  key={index}
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #ccc",
                                                  }}
                                                >
                                                  <GridContainer
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "center",
                                                    }}
                                                  >
                                                    <GridItem
                                                      xs={12}
                                                      sm={12}
                                                      md={1}
                                                    >
                                                      <p>{index} </p>
                                                    </GridItem>
                                                    <GridItem
                                                      xs={12}
                                                      sm={12}
                                                      md={5}
                                                    >
                                                      <p>{array.word} </p>
                                                    </GridItem>
                                                    <GridItem
                                                      xs={12}
                                                      sm={12}
                                                      md={4}
                                                    >
                                                      <p>{array.match}</p>
                                                    </GridItem>
                                                    <GridItem
                                                      xs={12}
                                                      sm={12}
                                                      md={2}
                                                    >
                                                      <IconButton
                                                        color="secondary"
                                                        aria-label="delete"
                                                        onClick={() =>
                                                          removeMatch(
                                                            array.word
                                                          )
                                                        }
                                                      >
                                                        <Delete />
                                                      </IconButton>
                                                    </GridItem>
                                                  </GridContainer>
                                                </div>
                                              )
                                            )
                                            : ""}
                                        </DialogContent>

                                        <DialogActions>
                                          <Button
                                            color="primary"
                                            onClick={() =>
                                              setOpenModalMatch(false)
                                            }
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            autoFocus
                                            onClose={() =>
                                              setOpenModalMatch(true)
                                            }
                                            color="secondary"
                                            // onClick={saveMatchWord}
                                            onClick={(e) => {
                                              saveSelection("", "", 3);
                                            }}
                                          >
                                            Save
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                      <Dialog
                                        open={openModalStudyList}
                                        onClose={() =>
                                          setOpenModalStudyList(false)
                                        }
                                        aria-labelledby="modal-slide-title"
                                        aria-describedby="modal-slide-description"
                                      >
                                        <DialogTitle
                                          id="classic-modal-slide-title"
                                          style={{ textAlign: "center" }}
                                        >
                                          <span
                                            style={{ textTransform: "none" }}
                                            className={
                                              classes1.originHeaderText
                                            }
                                          >
                                            {" "}
                                            Master Study Lists of the book{" "}
                                            <strong>
                                              {bookToDisplay &&
                                                bookToDisplay}
                                            </strong>
                                          </span>
                                        </DialogTitle>
                                        <DialogContent>
                                          <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                              <ol>
                                                {studyList &&
                                                  studyList.map(
                                                    (item, index) => {
                                                      return (
                                                        <li key={index}>
                                                          {item}{" "}
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                              </ol>
                                            </GridItem>
                                          </GridContainer>
                                        </DialogContent>

                                        <DialogActions>
                                          <Button
                                            color="primary"
                                            onClick={() =>
                                              setOpenModalStudyList(false)
                                            }
                                          >
                                            Close
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                    </div>
                                  ) : (
                                    <div>
                                      <div
                                        className={
                                          classes1.tableContainerChapterView
                                        }
                                      >
                                        <table
                                          className={classes1.tableChapterView}
                                        >
                                          <thead
                                            className={
                                              classes1.theadChapterView
                                            }
                                          >
                                            <tr>
                                              <th
                                                className={
                                                  classes1.thChapterView
                                                }
                                              >
                                                Number
                                              </th>
                                              <th
                                                className={
                                                  classes1.thChapterView
                                                }
                                              >
                                                Title
                                              </th>
                                              <th
                                                className={
                                                  classes1.thChapterView
                                                }
                                              >
                                                Number Of Words
                                              </th>
                                              <th
                                                className={
                                                  classes1.thChapterView
                                                }
                                              >
                                                {" "}
                                                Number Of unique Words
                                              </th>
                                              <th
                                                className={
                                                  classes1.thChapterView
                                                }
                                              >
                                                {" "}
                                                Number Of Sentences
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody
                                            className={
                                              classes1.tbodyChapterView
                                            }
                                          >
                                            {chapterView
                                              ? chapterView.map((item, i) => (
                                                <tr
                                                  key={i}
                                                  className={
                                                    classes1.trChapterView
                                                  }
                                                >
                                                  <td
                                                    className={
                                                      classes1.tdChapterView
                                                    }
                                                  >
                                                    {i + 1}
                                                  </td>
                                                  <Tooltip
                                                    title="View details"
                                                    placement="top"
                                                  >
                                                    <td
                                                      className={
                                                        classes1.tdChapterView
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                        textAlign: "left",
                                                      }}
                                                      onClick={() =>
                                                        getChapterDetail(
                                                          item.section
                                                        )
                                                      }
                                                    >
                                                      {item.section ==
                                                        "undefined"
                                                        ? "Undefined chapter"
                                                        : item.section}
                                                    </td>
                                                  </Tooltip>

                                                  <td
                                                    className={
                                                      classes1.tdChapterView
                                                    }
                                                  >
                                                    {item.totalWords}
                                                  </td>
                                                  <td
                                                    className={
                                                      classes1.tdChapterView
                                                    }
                                                  >
                                                    {" "}
                                                    {item.totalUniqueWords}
                                                  </td>
                                                  <td
                                                    className={
                                                      classes1.tdChapterView
                                                    }
                                                  >
                                                    {item.totalSentences}
                                                  </td>
                                                </tr>
                                              ))
                                              : ""}
                                          </tbody>
                                        </table>
                                      </div>
                                      {chapterDetail &&
                                        chapterDetail.title &&
                                        chapterDetail.content ? (
                                        <div>
                                          {/* {chapterDetail.title ==
                                          "undefined" ? ( */}
                                          <p
                                            style={{
                                              textAlign: "center",
                                              paddingTop: "20px",
                                            }}
                                          >
                                            <strong
                                              className={
                                                classes1.highlightStyle
                                              }
                                            >
                                              We need you to highlight the text
                                              for which you want to add the
                                              chapter and right click on the highlighted text
                                            </strong>
                                          </p>
                                          {/* ) : (
                                            ""
                                          )} */}
                                          <div
                                            className={classes1.divChaterView}
                                          >
                                            <GridContainer>
                                              <GridItem xs={12} sm={12} md={9}>
                                                <form
                                                  onSubmit={
                                                    handleSearchSubmitContent
                                                  }
                                                >
                                                  <GridContainer>
                                                    <GridItem
                                                      xs={9}
                                                      sm={9}
                                                      md={9}
                                                    >
                                                      <input
                                                        type="text"
                                                        placeholder="Search"
                                                        value={searchTerm}
                                                        onChange={
                                                          handleSearchChangeContent
                                                        }
                                                        ref={inputRef}
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      />
                                                    </GridItem>
                                                    <GridItem
                                                      xs={3}
                                                      sm={3}
                                                      md={3}
                                                    >
                                                      <button type="submit">
                                                        Search
                                                      </button>
                                                    </GridItem>
                                                  </GridContainer>
                                                </form>
                                              </GridItem>
                                              <GridItem xs={3} sm={2} md={1}>
                                                {/* {showAddChapterBtn && ( */}
                                                <Tooltip
                                                  title="Add a new chapter for the highlighted text"
                                                  placement="top"
                                                  style={{
                                                    color: "#ff9100",
                                                  }}
                                                >
                                                  <AddCircle
                                                    className={classes1.icon}
                                                    onClick={(e) => {
                                                      if (
                                                        highlightedText == ""
                                                      ) {
                                                        return toast.error(
                                                          "You have to highlight a text before",
                                                          {
                                                            position:
                                                              toast.POSITION
                                                                .TOP_CENTER,
                                                          }
                                                        );
                                                      }
                                                      setOpenChapterModal(true);
                                                      setRemoveChapter(false);
                                                      setIsInEditChapterMode(
                                                        false
                                                      );
                                                      setNewChapter("");
                                                    }}
                                                  />
                                                </Tooltip>
                                                {/* )} */}
                                              </GridItem>
                                              <GridItem xs={3} sm={2} md={1}>
                                                <Tooltip
                                                  title="Edit Chapter"
                                                  placement="top"
                                                  tyle={{
                                                    color: "#34566A",
                                                  }}
                                                >
                                                  <Edit
                                                    className={classes1.icon}
                                                    onClick={(e) => {
                                                      setOpenChapterModal(true);
                                                      setNewContent(
                                                        chapterDetail.content
                                                      );
                                                      setNewChapter(
                                                        chapterDetail.title
                                                      );
                                                      setIsInEditChapterMode(
                                                        true
                                                      );
                                                      setRemoveChapter(false);
                                                    }}
                                                  />
                                                </Tooltip>
                                              </GridItem>{" "}
                                              <GridItem xs={3} sm={2} md={1}>
                                                <Tooltip
                                                  title="Delete Test"
                                                  placement="top"
                                                  style={{
                                                    color: "red",
                                                  }}
                                                >
                                                  <Delete
                                                    className={classes1.icon}
                                                    onClick={(e) => {
                                                      if (
                                                        highlightedText == ""
                                                      ) {
                                                        return toast.error(
                                                          "You have to highlight a text before",
                                                          {
                                                            position:
                                                              toast.POSITION
                                                                .TOP_CENTER,
                                                          }
                                                        );
                                                      }
                                                      setOpenChapterModal(true);
                                                      setRemoveChapter(true);
                                                      setIsInEditChapterMode(
                                                        false
                                                      );
                                                    }}
                                                  />
                                                </Tooltip>
                                              </GridItem>
                                            </GridContainer>
                                          </div>
                                          <div
                                            className={classes1.paragraphStyle}
                                          >
                                            <p
                                              className={classes1.tableHeadCell}
                                              style={{ textAlign: "center" }}
                                            >
                                              {chapterDetail.title ==
                                                "undefined"
                                                ? "Undefined chapter"
                                                : chapterDetail.title}
                                            </p>
                                            {/* <p onMouseUp={handleHighlight}> */}{" "}
                                            {/* {chapterDetail.content} */}
                                            <p
                                              // style={
                                              //   highlighted
                                              //     ? {
                                              //         backgroundColor:
                                              //           "yellow !important",
                                              //       }
                                              //     : {backgroundColor:"#34566A"}
                                              // }
                                              className={highlighted && classes1.highlightStyle}
                                              onMouseUp={handleHighlight}
                                              dangerouslySetInnerHTML={{
                                                __html: paragraph,
                                              }}
                                            ></p>
                                            {/* </p> */}
                                          </div>
                                          <Dialog
                                            classes={{
                                              root: classes.center,
                                              paper: classes.modal,
                                            }}
                                            open={openChapterModal}
                                            onClose={() => {
                                              setOpenChapterModal(false);
                                              setRemoveChapter(false);
                                            }}
                                            aria-labelledby="modal-slide-title"
                                            aria-describedby="modal-slide-description"
                                          >
                                            <DialogTitle
                                              id="classic-modal-slide-title"
                                              disableTypography
                                              className={classes.modalHeader}
                                            >
                                              <p
                                                className={
                                                  classes1.tableHeadCell
                                                }
                                                style={{ textAlign: "center" }}
                                              >
                                                {removeChapter
                                                  ? "Are you sure you want to delete this text ?"
                                                  : isInEditChapterMode
                                                    ? "Edit Chapter"
                                                    : "Add Chapter"}
                                              </p>
                                            </DialogTitle>
                                            <DialogContent
                                              id="modal-slide-description"
                                              className={classes.modalBody}
                                            >
                                              {isInEditChapterMode ? (
                                                ""
                                              ) : (
                                                <p>{highlightedText}</p>
                                              )}
                                              {isInEditChapterMode && (
                                                <textarea
                                                  rows={8}
                                                  style={{
                                                    border: "none",
                                                    width: "100%",
                                                    fontSize: "large",
                                                  }}
                                                  name="meaning"
                                                  value={newContent}
                                                  onChange={(e) => {
                                                    setNewContent(
                                                      e.target.value
                                                    );
                                                  }}
                                                />
                                              )}
                                              {!removeChapter ? (
                                                <CustomInput
                                                  formControlProps={{
                                                    className:
                                                      classes.margin +
                                                      " " +
                                                      classes.search,
                                                  }}
                                                  labelText="Add a title"
                                                  id="float"
                                                  type="text"
                                                  inputProps={{
                                                    // placeholder: "Type in a word here",
                                                    inputProps: {
                                                      "aria-label": "Search",
                                                    },
                                                    value: newChapter,
                                                    onChange: (e) => {
                                                      setNewChapter(
                                                        e.target.value
                                                      );
                                                    },
                                                  }}
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </DialogContent>
                                            <DialogActions>
                                              <Button
                                                color="primary"
                                                onClick={closeChapterDialog}
                                              >
                                                Cancel
                                              </Button>
                                              <Button
                                                autoFocus
                                                onClose={() =>
                                                  setOpenChapterModal(false)
                                                }
                                                color="secondary"
                                                // onClick={saveMatchWord}
                                                onClick={saveNewChapter}
                                              >
                                                {removeChapter
                                                  ? "Delete"
                                                  : isInEditChapterMode
                                                    ? "Update"
                                                    : "Save"}
                                              </Button>
                                            </DialogActions>
                                          </Dialog>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                              ),
                            },
                            {
                              tabName: "In Progress Scans",
                              tabContent: (
                                <div>
                                  <MenuList role="menu">
                                    {allInProgressScanBooksArray.map(function (
                                      item,
                                      i
                                    ) {
                                      return (
                                        <MenuItem
                                          key={i}
                                          className={classes.dropdownItem}
                                        >
                                          {item}
                                        </MenuItem>
                                      );
                                    })}
                                  </MenuList>
                                </div>
                              ),
                            },
                            {
                              tabName: "Failed Scans",
                              tabContent: (
                                <div>
                                  <MenuList role="menu">
                                    {allFailedScanBooksArray.map(function (
                                      item,
                                      i
                                    ) {
                                      return (
                                        <MenuItem
                                          key={i}
                                          className={classes.dropdownItem}
                                        >
                                          {item}
                                        </MenuItem>
                                      );
                                    })}
                                  </MenuList>
                                </div>
                              ),
                            },
                          ]}
                        ></CustomTabs>
                      </GridItem>
                    ),
                  },
                ]}
                title={
                  <Button
                    color="primary"
                    onClick={() => window.location.reload(false)}
                    style={{
                      margin: "-2px 0 5px 0",
                      padding: "10px",
                      color: "#FFFFFF",
                    }}
                  >
                    <AutorenewOutlined />
                    Refresh
                  </Button>
                }
              />
            </Grid>
          </Grid>
        </div>
      ) : (
        <h1 style={{ textAlign: "center" }}>
          Unauthorized page. Please contact admin
        </h1>
      )}
    </div>
  );
}
